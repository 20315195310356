import classnames from 'classnames'
import React from 'react'
import { Button } from 'reactstrap'

export interface ExpandablePreferenceItemProps extends React.HTMLAttributes<HTMLElement> {
  buttonDisabled?: boolean,
  buttonText?: string,
  heading: string;
  preferenceKey: string,
  onButtonClick?: any,
  onChange?: any,
  onCollapse?: any,
  onSelectPreference: any,
  selectedPreference: string,
  showButton?: boolean,
  showPanelItem: boolean,
}

export class ExpandablePreferenceItem extends React.Component<ExpandablePreferenceItemProps> {
  constructor(props: ExpandablePreferenceItemProps) {
    super(props)
    this.onButtonClick = this.onButtonClick.bind(this)
    this.onCollapse = this.onCollapse.bind(this)
  }
  public render() {
      return (
        <div className={'preference-box expanded-preference-box'}>
        <div className="box-body">
          <div className="left">
            <div className="box-header">{this.props.heading}</div>
            <div className="box-content">
            {this.props.showPanelItem ?
              <div className="expanded-preference-box">
                <div className="expanded-preference-box-content">
                  {this.props.children}
                </div>
              </div>
            : <div></div>
            }
            </div>
          </div>
          <div className={classnames('right', this.props.showPanelItem ? 'expanded' : '')}>
            {this.props.showPanelItem &&
              <div className="panel-bar-collapse" onClick={this.onCollapse}>
                <span className="k-icon k-i-arrow-n k-panelbar-collapse"></span>
              </div>
            }
            {this.props.showButton &&
            <Button disabled={this.disableButton()} onClick={this.onButtonClick}>
              {this.props.buttonText}
            </Button>}
          </div>
        </div>
        <div className="clearfix"></div>
      </div>
      );
  }
  private onCollapse = () => {
    this.props.onSelectPreference()
    this.props.onCollapse()
  }
  private onButtonClick = () => {
    if (!this.props.showPanelItem) {
      this.props.onSelectPreference(this.props.preferenceKey)
    }
    this.props.onButtonClick()
  }
  private disableButton = (): boolean => {
    if (this.props.buttonDisabled || (this.props.selectedPreference &&
        this.props.selectedPreference !== this.props.preferenceKey)) {
      return true
    } else {
      return false
    }
  }
}
