import * as Flash from 'components/flash'
import { RasaContext } from 'context'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import { isPasswordValidationFailedError, logout, validatePassword } from 'generic/utility'
import { isNil } from 'lodash'
import React from 'react'
import { TextBox } from '../textbox'
import * as Types from '../types'
import * as Constants from './constants'
import { ExpandablePreferenceItem } from './expandablePreferenceItem'

interface PasswordPreferenceState {
  confirmPassword: string,
  errorMessage: string,
  isInvalidCurrentPassword: boolean,
  newPassword: string,
  currentPassword: string,
  showPanelItem: boolean,
}
const initialState: PasswordPreferenceState = {
  confirmPassword: '',
  errorMessage: '',
  isInvalidCurrentPassword: false,
  newPassword: '',
  currentPassword: '',
  showPanelItem: false,
}
interface PasswordPreferenceProps extends Types.ProfilePreferencesProps {
  hasCognitoIdentifier: boolean,
  selectedPreference: string,
  onSelectPreference: (key: string) => void,
  updateStatus: () => void,
  updateFailedStatus: () => void,
}

export class PasswordPreference extends React.Component<PasswordPreferenceProps, any> {
  public static contextType = RasaContext
  constructor(props: PasswordPreferenceProps) {
    super(props)
    this.state = initialState
  }
  public render() {
    return (
      <ExpandablePreferenceItem
        preferenceKey="password-preference"
        selectedPreference={this.props.selectedPreference}
        onSelectPreference={this.props.onSelectPreference}
        heading="Password"
        showPanelItem={this.state.showPanelItem}
        showButton={true}
        buttonText={this.props.hasCognitoIdentifier ? 'Change Password' : 'Set Password'}
        buttonDisabled={!this.isDirty()}
        onButtonClick={this.onPasswordChange}
        onCollapse={() => this.setState(initialState)}
        >
        <div className="error-message">
          {this.renderErrorMessage()}
        </div>
        <div className="clearfix "></div>
        {this.props.hasCognitoIdentifier ?
        <div className="field-left">
          {this.createTextBox('CURRENT PASSWORD', 'currentPassword')}
        </div> : <div></div>}
        <div className="clearfix "></div>
        <div className="field-left">
          {this.createTextBox('NEW PASSWORD', 'newPassword')}
        </div>
        <div className="field-right">
          {this.createTextBox('CONFIRM PASSWORD', 'confirmPassword')}
        </div>
      </ExpandablePreferenceItem>
    );
  }
  public renderErrorMessage = () => {
    return this.state.isInvalidCurrentPassword ?
       <div>The password you provided is incorrect.  If you do not remember your current password please
        use the <a href="/forgot-password">Forgot Password</a> link to reset it.</div>
    : this.state.errorMessage ? <div>{this.state.errorMessage}</div> : <div></div>
  }
  private isDirty = () => {
    return !this.state.showPanelItem || ((!this.props.hasCognitoIdentifier
      || this.state.currentPassword !== '') && this.state.newPassword !== ''
    && this.state.confirmPassword !== '' && this.state.newPassword === this.state.confirmPassword)
    && this.state.errorMessage === ''
  }
  private createTextBox(displayName: string, fieldName: string) {
    return <TextBox name={displayName}
                    dirty={!isNil(this.state[fieldName])}
                    field={fieldName}
                    value={this.state[fieldName]}
                    onChange={this.onChange}
                    type="password"/>
  }
  private onChange = (field, value) => {
    if (field === 'newPassword') {
      this.setState({ errorMessage: validatePassword(value) })
    } else if (field === 'confirmPassword' && this.state.newPassword !== value) {
      this.setState({ errorMessage: 'Password does not match.' })
    } else {
      this.setState({ errorMessage: '' })
    }
    this.setState({ [field]: value });
  }
  private clearState = () => {
    this.setState(initialState)
    this.props.onSelectPreference('')
  }
  private onPasswordChange = () => {
    if (!this.state.showPanelItem) {
      this.setState({showPanelItem: true});
      return;
    }
    this.setState({ isInvalidCurrentPassword: false })
    const url: string = AjaxWrapper.getServerUrl() + '/auth/change-password'
    AjaxWrapper.ajax(url, HttpMethod.POST, {
      new_password: this.state.newPassword,
      current_password: this.state.currentPassword})
    .then(() => {
      this.clearState()
      this.context.store.dispatch(Flash.showFlashMessage(Constants.PASSWORD_CHANGED))
      logout()
    })
    .catch((error) => {
      const errorMessage = error.response && error.response.message ? error.response.message : error
      this.setState({
        isInvalidCurrentPassword: !isPasswordValidationFailedError(errorMessage),
        errorMessage,
      })
    });
  }
}
