import { isEmpty, isNil } from 'lodash'
import React from 'react'
import { TextBox } from '../textbox'
import * as Types from '../types'
import { FieldNames, NAME_EXCLUDE_KEYWORDS } from './constants'
import { PreferenceItem } from './preferenceItem'

interface PersonalPreferenceProps extends Types.ProfilePreferencesProps {
  selectedPreference: string,
  updateStatus: () => void,
  updateFailedStatus: () => void,
}

export class PersonalPreference extends React.Component<PersonalPreferenceProps> {
  constructor(props: PersonalPreferenceProps) {
    super(props)
  }
  public render() {
      return (
        <PreferenceItem preferenceKey="personal-preference" className="personal-preference"
          selectedPreference={this.props.selectedPreference} onButtonClick={this.props.saveRecord}
          heading="Personal Info" showButton={true} buttonText="Update"
          buttonDisabled={!this.isDirty()}>
          <div>
            <div className="field-left">
              {this.createTextBox('FIRST NAME', FieldNames.FIRST_NAME)}
            </div>
            <div className="field-right">
              {this.createTextBox('LAST NAME', FieldNames.LAST_NAME)}
            </div>
          </div>
        </PreferenceItem>
      );
  }

  private isDirty = () => {
    let bDirty: boolean = false
    // if first name is changed, then it must be valid
    if (!isNil(this.props.changes[FieldNames.FIRST_NAME])) {
      if (this.isInVaild(this.props.changes[FieldNames.FIRST_NAME])) {
        return false
      } else {
        bDirty = true
      }
    }
    // if last name is changed, then it must be valid
    if (!isNil(this.props.changes[FieldNames.LAST_NAME])) {
      if (this.isInVaild(this.props.changes[FieldNames.LAST_NAME])) {
        return false
      } else {
        bDirty = true
      }
    }

    // if we reach here, just check if there is any change
    return bDirty
  }

  private createTextBox(displayName: string, fieldName: string) {
    return <TextBox name={displayName}
                    dirty={!isNil(this.props.changes[fieldName])}
                    field={fieldName}
                    value={this.props.profile[fieldName]}
                    onChange={this.props.onChange}/>
  }

  private isInVaild = (fieldValue) => {
    if (isEmpty(fieldValue)) {
      return true
    }
    return NAME_EXCLUDE_KEYWORDS.some((element) => {
      if (fieldValue.includes(element)) {
        return true;
      }
      return false;
    });
  }
}
