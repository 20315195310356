import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import React from 'react'
import { EmailVerificationStatus } from 'shared_server_client/constants'
import { formatDate } from 'shared_server_client/dates'
import * as Types from '../types'
import { PreferenceItem } from './preferenceItem'

interface EmailPreferenceProps extends Types.ProfilePreferencesProps {
  selectedPreference: string,
  updateStatus: () => void,
  updateFailedStatus: () => void,
}

export class EmailPreference extends React.Component<EmailPreferenceProps> {
  constructor(props: EmailPreferenceProps) {
    super(props)
  }
  public render() {
      return (
        <PreferenceItem preferenceKey="email-preference" className="email-preference" heading="Email Address"
          selectedPreference={this.props.selectedPreference}
          showButton={false} buttonText="Edit Email Address" buttonDisabled={true}
          isComingSoon={true}>
        <div>
          <div className="email-verification-left">
            {this.props.profile.email}
          </div>
          <div className="right email-verification-right">
          {((this.props.verificationStatus !== EmailVerificationStatus.confirmed) &&
            (this.props.lastSentDate)) &&
            <div className="last-email-wrap">
              <div >
                Your last verification email was sent on
              </div>
              <div className="next-email-wrapper-date">
                {this.props.lastSentDate && formatDate(this.props.lastSentDate)}
              </div>
            </div>}
          {this.props.verificationStatus === EmailVerificationStatus.confirmed
          ? <div className="save-button save-button-disabled verified" >
              Verified
            </div>
          : (this.props.verificationStatus === EmailVerificationStatus.pending)
          ?
            <div className="save-button save-button-disabled pending" >
              Pending
            </div>
          :
            <div className="save-button save-button-enabled clickable-item" onClick={this.sendVerification}>
              Send Verification Email
            </div>}
            {(this.props.verificationStatus === EmailVerificationStatus.failed) &&
            <div className="field-right resend-message save-button save-button-enabled clickable-item"
                onClick={this.sendVerification}>
              Re-send Verification Email
            </div>}
          </div>
        </div>
      </PreferenceItem>
      );
  }
  private sendVerification = () => {
    this.props.updateStatus()
    AjaxWrapper.ajax(AjaxWrapper.getServerUrl() + `/${this.props.communityId}/verify-email`, HttpMethod.POST, {})
               .catch(() => this.props.updateFailedStatus())
  }
}
