import * as Flash from 'components/flash'
import { RasaContext } from 'context'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import { Dataset } from 'generic/dataset'
import { isNil } from 'lodash'
import React from 'react'
import { Button } from 'reactstrap'
import { loadRecaptcha, withRecaptcha } from '../../../recaptcha'
import { TextBox } from '../textbox'
import * as Types from '../types'
import { formatPhone } from '../utils'
import * as Constants from './constants'
import { ExpandablePreferenceItem } from './expandablePreferenceItem'
import { UserTwoFactorMethods } from './userTwoFactorMethods'

interface TwoFactorPreferenceState {
  confirmationCode: string,
  confirmationCodeSent: boolean,
  user2faId: string,
  errorMessage: string,
  mobileNumber: string,
  showPanelItem: boolean,
  user2faData?: any,
}
const initialState: TwoFactorPreferenceState = {
  confirmationCode: '',
  confirmationCodeSent: false,
  errorMessage: '',
  mobileNumber: '',
  showPanelItem: false,
  user2faId: '',
}
interface TwoFactorPreferenceProps extends Types.ProfilePreferencesProps {
  hasCognitoIdentifier: boolean,
  selectedPreference: string,
  onSelectPreference: (key: string) => void,
}

export class TwoFactorPreference extends React.Component<TwoFactorPreferenceProps, any> {
  public static contextType = RasaContext
  constructor(props: TwoFactorPreferenceProps) {
    super(props)
    this.state = {...initialState, user2faData: []}
    this.confirm2fa = this.confirm2fa.bind(this)
  }
  public componentDidMount() {
    this.load2faData()
    loadRecaptcha()
  }
  public render() {
    return (
      <ExpandablePreferenceItem
        preferenceKey="2fa-preference"
        selectedPreference={this.props.selectedPreference}
        onSelectPreference={this.props.onSelectPreference}
        heading="Two-factor authentication"
        showPanelItem={this.state.showPanelItem}
        showButton={!this.state.showPanelItem || this.state.user2faData.length === 0}
        buttonText={!this.state.showPanelItem ?
                        this.state.user2faData.length > 0 ? 'Configure your 2FA' : 'Setup your 2FA'
                    : this.state.confirmationCodeSent ? 'Start Over' : 'Add your phone' }
        buttonDisabled={!this.isDirty()}
        onButtonClick={this.onButtonClick}
        onCollapse={() => this.setState({
          errorMessage: '',
          mobileNumber: '',
          confirm: '',
          showPanelItem: false,
        })}
        >
        {this.state.errorMessage &&
        <div className="error-message">
          {this.state.errorMessage}
        </div>}
        <div className="clearfix "></div>
        <div className="field-left">
          { this.state.user2faData.length === 0 ?
              !this.state.confirmationCodeSent ?
                this.createTextBox('Mobile Number', 'mobileNumber', '+1 (XXX) XXX-XXXX')
              :
              <div>
                <div className="field-left">
                  {this.createTextBox('Confirmation Code', 'confirmationCode')}
                  <div className="confirm-text">
                    We have sent a confirmation code to your mobile number: {this.state.mobileNumber}
                  </div>
                </div>
                <div className="field-left">
                  <Button disabled={this.state.confirmationCodeSent && this.state.confirmationCode === ''}
                    onClick={this.confirm2fa}>
                    Confirm
                  </Button>
                </div>
              </div>
            : <div></div>}
        </div>
        <div className="clearfix "></div>
        <UserTwoFactorMethods {...this.props} data={this.state.user2faData} reloadData={this.load2faData} />
      </ExpandablePreferenceItem>
    );
  }
  private clearState = () => {
    this.setState(initialState)
    this.props.onSelectPreference('')
  }
  private isDirty = () => {
    return !this.state.showPanelItem || (!this.state.errorMessage &&
      ((!this.state.confirmationCodeSent && this.state.mobileNumber !== ''))
      || this.state.confirmationCodeSent)
  }
  private createTextBox(displayName: string, fieldName: string, placeholder?: string) {
    return <TextBox name={displayName}
              placeholder={placeholder}
              dirty={!isNil(this.state[fieldName])}
              field={fieldName}
              value={this.state[fieldName]}
              onChange={this.onChange} />
  }
  private onChange = (field, value) => {
    this.setState({ [field]: value });
  }

  private onButtonClick = () => {
    if (!this.state.showPanelItem) {
      this.setState({showPanelItem: true});
    } else if (this.state.confirmationCodeSent) {
      this.clearState()
    } else {
      withRecaptcha('send2fa', this.add2fa)
    }
  }

  private add2fa = (token: string) => {
    const url: string = AjaxWrapper.getServerUrl() + '/auth/add-2fa'
    AjaxWrapper.ajax(url, HttpMethod.POST, {
      recaptcha: token,
      user2fa_identifier: formatPhone(this.state.mobileNumber)})
    .then((res) => {
      this.setState({
        confirmationCodeSent: true,
        user2faId: res.user2fa_id,
      })
      this.context.store.dispatch(Flash.showFlashMessage(Constants.CONFIRMATION_SENT))
    })
    .catch((error) => this.setState({
      isInvalidCurrentPassword: true,
    }));
  }
  private confirm2fa = () => {
    const url: string = AjaxWrapper.getServerUrl() + '/auth/confirm-2fa'
    AjaxWrapper.ajax(url, HttpMethod.POST, {
      user2fa_id: this.state.user2faId,
      confirmation_code: this.state.confirmationCode})
    .then((res) => {
      this.clearState()
      this.load2faData()
      this.props.onSelectPreference('')
      this.context.store.dispatch(Flash.showFlashMessage(Constants.TWOFACTOR_ADDED))
    })
    .catch((error) => this.setState({
      isInvalidCurrentPassword: true,
    }));
  }
  private load2faData = () => {
    return new Dataset().loadGlobalDataset('user2fa')
      .then((data) => {
        this.setState({user2faData: data[0]})
      })
  }
}
