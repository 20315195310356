import React from 'react'
import { copyToClipboard } from '../../generic/utility'

export const statusBanner = (status: string) => {
  if (status === null) {
    return <span className="status-pending">Pending</span>
  } else if (status === 'valid') {
    return <span className="status-valid">Valid</span>
  } else if (status === 'invalid') {
    return <span className="status-invalid">Invalid</span>
  }
}

export const CopyButton = ({text}: any) =>
<div className="copy-button"
  onClick={() => copyToClipboard(text)}>
  Copy
</div>

export const formatPhone = (phone: string) => {
  let hasPhoneISDCode = false
  phone = phone.trim()
  if (phone.startsWith('+')) {
    hasPhoneISDCode = true
  }
  let formattedPhone = phone.replace(/\D/g, '') // remove all non digit
  if (hasPhoneISDCode) {
    // ISD code already included. just add the + sign back and we should be good
    formattedPhone = '+' + formattedPhone
  } else {
    // Doesn't  have ISD Code
    if (formattedPhone.length > 10) {
      // Assuming phone already has country code. We just need to add + sign
      formattedPhone = '+' + formattedPhone
    } else {
      // Assuming person didn't provide ISD Code. Do our best guess and add + 1 (probably this is US no)
      formattedPhone = '+1' + formattedPhone
    }
  }
  return formattedPhone
}

export const maskPhone = (phone: string) => {
  return '(XXX) XXX-' + phone.slice(phone.length - 4)
}
