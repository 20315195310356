import * as Flash from 'components/flash'
import { RasaContext } from 'context'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import React from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import * as Types from '../types'
import { maskPhone } from '../utils'
import * as Constants from './constants'

interface UserTwoFactorMethodsPreferenceProps extends Types.ProfilePreferencesProps {
  hasCognitoIdentifier: boolean,
  data: any,
  reloadData: () => void,
}

interface UserTwoFactorMethodsPreferenceState {
  deleteId: string,
  showDeleteModal: boolean,
}

export class UserTwoFactorMethods extends React.Component<UserTwoFactorMethodsPreferenceProps,
  UserTwoFactorMethodsPreferenceState> {
  public static contextType = RasaContext
  constructor(props: UserTwoFactorMethodsPreferenceProps) {
    super(props)
    this.state = {
      deleteId: '',
      showDeleteModal: false,
    }
  }

  public render() {
    return (
      this.props.data.length > 0 ?
      this.renderTwoFactorMethods()
      :
      <div></div>
    );
  }
  private renderTwoFactorMethods = () =>
  <div className="user-2fa-list">
    {this.getDelete2faModalJSX()}
    {this.props.data.map((user2fa) => {
      return <div className="user-2fa-list-item" key={user2fa.id}>
        <div className="field-left lbl">
          {user2fa['2fa_service_name'] === Types.TwoFactorService.SMS ?
            maskPhone(user2fa['2fa_identifier']) : user2fa['2fa_identifier'] }
        </div>
        <div className="field-left lbl">
          {user2fa['2fa_service_name']}
        </div>
        <div className="field-left">
        <Button className="btn-delete" onClick={() => this.onDelete(user2fa.id)}>
          Delete
        </Button>
        </div>
      </div>
    })}
  </div>

  private onDelete(id: string) {
    this.setState({
      deleteId: id,
      showDeleteModal: true,
    })
  }

  private getDelete2faModalJSX(): JSX.Element {
    return (
      <div>
        <Modal isOpen={this.state.showDeleteModal}
               className="schedule-pause-confirmation-modal"
               size="lg"
               fade={false}
               centered={true} >
          <ModalHeader>Two Factor Delete Confirmation</ModalHeader>
          <ModalBody className="schedule-pause-confirmation-modal-body">
            <div>
              <h4>Are You Sure?</h4>
              <p>You are about to delete your two factor authentication. Would you like to proceed?</p>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button onClick={this.onConfirmDelete}>
              Yes
            </Button>
            <Button onClick={this.onModalClose}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
  private onModalClose = () => {
    this.setState({
      showDeleteModal: false,
    })
  }
  private onConfirmDelete = () => {
    const url: string = AjaxWrapper.getServerUrl() + '/auth/delete-2fa'
    AjaxWrapper.ajax(url, HttpMethod.POST, {
      user2fa_id: this.state.deleteId})
    .then((res) => {
      this.props.reloadData()
      this.context.store.dispatch(Flash.showFlashMessage(Constants.TWOFACTOR_DELETED))
      this.setState({
        deleteId: '',
        showDeleteModal: false,
      })
    })
    .catch((error) => this.context.store.dispatch(Flash.showFlashError(Constants.TWOFACTOR_DELETE_FAILED)));
  }
}
