export const PASSWORD_CHANGED = 'Your password has been changed successfully.'
export const PROFILE_UPDATED = 'Your profile has been updated successfully.'
export const TWOFACTOR_ADDED = 'Your two factor authentication has been confirmed.'
export const CONFIRMATION_SENT = 'We have sent a code to your phone.  Please enter the code to complete the two factor setup.'
export const TWOFACTOR_DELETED = 'Your two factor method has been removed successfully.'
export const TWOFACTOR_DELETE_FAILED = 'Unable to delete your two factor method. Please try after sometimes'
export enum FieldNames {
  FIRST_NAME = 'first_name',
  LAST_NAME = 'last_name',
}

export const NAME_EXCLUDE_KEYWORDS = ['https://', 'http://', 'www.', '@']
