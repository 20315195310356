import { EmailVerificationStatus } from 'shared_server_client/constants'

export interface FullProfile {
  account_address_1: string,
  account_address_2: string,
  account_billing_system_identifier: string,
  account_city: string,
  account_country: string,
  account_department: string,
  account_id: string,
  account_phone_1: string,
  account_phone_2: string,
  account_post_code: string,
  account_state: string,
  account_title: string,
  account_website: string,
  address_1: string,
  address_2: string,
  billing_system_identifier: string,
  city: string,
  company_name: string,
  community_name: string,
  country: string,
  display_name?: string,
  email: string,
  whitelabel_logo_url: string,
  facebook_handle: string,
  first_name: string,
  last_name: string,
  linkedin_handle: string,
  options: string,
  phone: string,
  post_code: string,
  state: string,
  timezone: string,
  twitter_handle: string,
  website: string,
}

export type OnChange = (field: string, value: string) => void
export interface CompanyProps extends ProfileProps {
  isAdminUser: boolean;
}
export interface ProfileProps {
  changes?: any,
  isAdminUser?: boolean,
  profile: FullProfile,
  onChange: OnChange,
}
export interface ProfilePreferencesProps extends ProfileProps {
  communityId: string,
  verificationStatus: EmailVerificationStatus
  lastSentDate: string,
  hasCognitoIdentifier: boolean,
  is2faRequired: boolean,
  updateStatus: () => void,
  updateFailedStatus: () => void,
  saveRecord: () => void,
}
export enum TwoFactorService {
  SMS = 'SMS',
}

export enum IntegrationTabMenus {
  active = 'active_integrations',
  availableIntegrations = 'available_integrations',
}

export interface Community {
  id: number,
  identifier: string,
  name: string,
}
