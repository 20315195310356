import * as Flash from 'components/flash'
import { HeaderComponent } from 'components/header/component'
import { Loading } from 'components/loading'
import { RasaContext } from 'context'
import { differenceInSeconds, parseISO } from 'date-fns'
import { BaseClientEntity, RecordIdType } from 'generic/baseClientEntity'
import * as GenericRedux from 'generic/genericRedux'
import { RasaReactComponent } from 'generic/rasaReactComponent'
import { isTruthy } from 'generic/utility'
import { isEmpty } from 'lodash'
import React from 'react'
import { Roles } from 'shared/constants'
import { EmailVerificationStatus } from 'shared_server_client/constants'
import '../styles.css'
import * as Types from '../types'
import * as Constants from './constants'
import {ProfilePreferences} from './preferences'

type ProfileProps = GenericRedux.AllComponentProps<Types.FullProfile>

interface ProfileState {
  activeCommunity: any,
  changes: any,
  isProfileLoading: boolean,
  person: any,
  verificationStatus: EmailVerificationStatus,
  lastSentDate: string,
  stillPending: boolean,
  isAdminUser: boolean,
  hasCognitoIdentifier: boolean,
  is2faRequired: boolean,
}

class ProfileComponent extends RasaReactComponent<ProfileProps, ProfileState> {
  public static contextType = RasaContext

  constructor(props: ProfileProps) {
    super(props, 'userAndAccount', {
      activeCommunity: {},
      changes: {},
      isProfileLoading: true,
      person: {},
      verificationStatus: EmailVerificationStatus.unknown,
      lastSentDate: '',
      stillPending: null,
      isAdminUser: false,
      hasCognitoIdentifier: false,
      is2faRequired: false,
    })
    this.saveRecord = this.saveRecord.bind(this)
  }

  public componentDidMount = () => {
    this.context.user.init().then(({person, activeCommunity, role}) => {
      this.setState({
        activeCommunity,
        communityId: activeCommunity.communityId,
        person,
        verificationStatus: person.verified ?
                            EmailVerificationStatus.confirmed :
                            this.getLastEmailTimePassed(person.lastPendingVerificationSent) ?
                            EmailVerificationStatus.pending :
                            EmailVerificationStatus.unknown,
        lastSentDate: person.lastPendingVerificationSent,
        stillPending: this.getLastEmailTimePassed(person.lastPendingVerificationSent),
        isAdminUser: activeCommunity.role === Roles.super_admin,
        is2faRequired: isTruthy(person.accountInfo.is_2fa_required),
        hasCognitoIdentifier: !isEmpty(person.accountInfo.cognito_identifier),
      })
      return this.loadRecord(activeCommunity.communityId, activeCommunity.communityAccountId).then(() => {
        return this.setState({isProfileLoading: false})
      })
    })
  }

  public getLastEmailTimePassed = (lastSent: string) => {
    const sent = new Date(parseISO(lastSent))
    const now = new Date()
    const lastEmailTime = differenceInSeconds(now, sent)
    //return pending still true if 6hrs within last time email sent
    return lastEmailTime < 21600
  }

  public loadRecord(communityId: string, recordId: RecordIdType): Promise<boolean> {
    this.setState({
      isDirty: false,
      changes: {},
    })
    const accountData = this.state.person.accountInfo
    this.updateComponentState({
      email: accountData.email,
      first_name: accountData.first_name,
      last_name: accountData.last_name,
    })
    return Promise.resolve(true)
  }

  public mapPropsToEntityObject(entityObject: BaseClientEntity): void {
    Object.keys(this.state.changes).forEach((key: string) => {
      entityObject.data[key] = this.state.changes[key]
    })
  }

  public propertyChanged(field: string, value: string) {
    super.propertyChanged(field, value)
    this.setState({
      changes: {
        ...this.state.changes,
        [field]: value,
      },
    })
  }

  public render() {
    return (
      <div className="settings-wrapper">
        {this.state.isProfileLoading ? <Loading size="64"/> :
        <div className="menuline1">
          <div className="header-container">
            <HeaderComponent
              title={'Settings'}
              subTitle={'Profile'}
            />
          </div>

          <div className="borderline2"></div>
          <div className="clearfix "></div>
          <ProfilePreferences profile={this.props.data}
                           verificationStatus={this.state.verificationStatus}
                           communityId={this.state.activeCommunity.communityId}
                           hasCognitoIdentifier={this.state.hasCognitoIdentifier}
                           is2faRequired={this.state.is2faRequired}
                           lastSentDate={this.state.lastSentDate}
                           onChange={(field, value) => this.propertyChanged(field, value)}
                           changes={this.state.changes}
                           updateStatus={this.updateStatus}
                           updateFailedStatus={this.updateFailedStatus}
                           saveRecord={this.saveRecord}/>
        </div>
        }
      </div>
    )
  }
  public updateStatus = () => {
    this.setState({verificationStatus: EmailVerificationStatus.pending})
  }

  public updateFailedStatus = () => {
    this.setState({verificationStatus: EmailVerificationStatus.failed})
  }

  public saveRecord(): Promise<any> {
    return super.saveRecord(this.state.communityId)
            .then((result: any) => {
              this.context.store.dispatch(Flash.showFlashMessage(Constants.PROFILE_UPDATED))
              this.setState({
                changes: {},
              })
              return result
            })
            .catch((err) => {
              // eslint-disable-next-line no-console
              console.log('Error: ', err)
              this.setState({ isSaving: false })
            })
  }
}

export const SettingsProfile = GenericRedux.registerNewNestedComponent<Types.FullProfile>(
  ProfileComponent, 'settings_profile')
