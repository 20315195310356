import { FeatureUnavailableComponent } from 'components/feature-unavailable'
import * as Flash from 'components/flash'
import { HeaderComponent } from 'components/header/component'
import { Loading } from 'components/loading'
import { RasaContext } from 'context'
import { Dataset } from 'generic/dataset'
import * as GenericRedux from 'generic/genericRedux'
import * as Utils from 'generic/utility'
import * as GA from 'google-analytics'
import { isEmpty } from 'lodash'
import React, {ComponentType} from 'react'
import {Button, Input} from 'reactstrap'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import { parseDate } from 'shared_server_client/dates'
import * as BillingPlan from 'shared_server_client/types/billing_plan'
import * as GlobalConstants from '../../constants'
import * as Constants from './constants'
import { DomainEmailSettingsModal } from './modals'
import { CopyButton, statusBanner} from './utils'
import { ConnectedComponentClass } from 'react-redux'
import {Fields} from "../../shared/modals"

export interface Domain {
  account_id: number
  created: string
  community_name: string
  dns_data: string
  domain: string
  id: number
  last_verified: any
  sendgrid_id: string
  status: any
  updated: string
}

interface DomainListState {
  communityId: string,
  deleting: boolean,
  domains: Domain[],
  isAccountOwner: boolean,
  loaded: boolean,
  saving: boolean,
  validating: boolean,
  currentId: any,
  emailLayoutId: any,
  plan?: BillingPlan.BillingPlan,
}

const unvalidatableDomains: string[] = [
  'gmail.com',
  'yahoo.com',
  'aol.com',
  'outlook.com',
]

export class SettingsDomainAuthentication extends React.Component<object, DomainListState> {

  public static contextType = RasaContext;
  private sharedStore: SharedStore
  constructor(props: any) {
    super(props)
    this.state = {
      communityId: '',
      deleting: false,
      domains: [],
      isAccountOwner: false,
      loaded: false,
      saving: false,
      validating: false,
      currentId: null,
      emailLayoutId: null,
    }
  }

  public componentDidMount = () => {
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity),
      this.sharedStore.getValue(SharedKeys.person),
    ])
    .then(([activeCommunity, person]) => {
      this.setState({
        communityId: activeCommunity.communityId,
        emailLayoutId: activeCommunity.communityInfo.data.email_layouts.filter((s: any) => s.is_active)[0].id,
        isAccountOwner: Utils.isAccountOwner(person, activeCommunity),
        plan: activeCommunity.billingInfo.currentPlan,
      })
      this.refreshRecords(activeCommunity.communityId)
    })
  }

  public render() {
    return (
      <div className="domain-auth">
        {this.state.loaded &&
        <div>
        {
          this.canAddDomains() ?
            <CreateDomainComponent create={this.createDomain} isSaving={this.state.saving}/>
            :
            <FeatureUnavailableComponent source={GA.UpgradeSource.DomainAuthentication}/>
        }
        </div>}
        {this.state.domains.length ?
        <div>
          <p>
            Create the CNAME records below in your DNS (usually where you purchased your domain).
            <br/>
            Once they are created, press the <strong>Validate</strong> button to check the status.
          </p>
          <div className="instructions">
            <em>Please realize it can take up to four hours for the records to be properly propagated.</em>
            <br/>
            Read <a href={GlobalConstants.RASA_HELP_HOW_TO_AUTH_DOMAIN}
                    target="_blank" rel="noopener">here</a> for more detailed instructions.
          <hr/>
          </div>
          <div>
            {this.state.domains.map((d: Domain) => {
              return <DomainsTable key={d.id}
              d={d}
              validateDomain={this.validateDomain}
              deleteDomain={this.deleteDomain}
              currentId={this.state.currentId}
              isValidating={this.state.validating}
              isDeleting={this.state.deleting}
              isAccountOwner={this.state.isAccountOwner}/>
            })}
          </div>
        </div>
        : null}
      </div>
    )
  }

  private createDomain = (domain: string, openModal: any) => {
    this.setState({
      saving: true,
    }, () => {
      this.context.entityMetadata.getEntityObject('emailDomain')
        .then((entityObject: any) => {
          entityObject.setRecordId(this.state.communityId, null)
          entityObject.data.domain = domain
          entityObject.save().then((result) => {
            this.context.store.dispatch(Flash.showFlashMessage(Constants.DOMAIN_AUTH_SUCCESS))
            openModal()
            this.refreshRecords()
            this.setState({
              saving: false,
            })
          })
          .catch((error) => {
            this.context.store.dispatch(Flash.showFlashError(Constants.DOMAIN_AUTH_FAILURE))
            this.setState({
              saving: false,
            })
          })
        })
    })
  }

  private validateDomain = (domain: any) => {
    this.setState({validating: true, currentId: domain.id}, () => {
      this.context.entityMetadata.getEntityObject('emailDomain')
        .then((entityObject: any) => {
          entityObject.setRecordId(this.state.communityId, domain.id)
          entityObject.save().then((result) => {
            this.context.entityMetadata.getEntityObject(
              'email_layout', this.state.communityId, this.state.emailLayoutId)
              .then((emailEntityObject: any) => {
                const address = emailEntityObject._data.from_address
                if (address) {
                  this.context.store.dispatch(({
                    address,
                    type: 'DOMAIN_VALIDATED',
                  }))
                }
              })
            domain.status = 'valid'
            this.setState({validating: false})
          })
          .catch((error) => {
            this.context.store.dispatch(Flash.showFlashError(Constants.DOMAIN_VERIFY_FAILURE))
            domain.status = 'invalid'
            this.setState({validating: false})
          })
        })
    })
  }

  private deleteDomain = (domainId: any) => {
    this.setState({deleting: true, currentId: domainId}, () => {
      this.context.entityMetadata.getEntityObject('emailDomain')
        .then((entityObject: any) => {
          entityObject.setRecordId(this.state.communityId, domainId)
          entityObject.archive().then((result) => {
            this.context.store.dispatch(Flash.showFlashMessage(Constants.DOMAIN_DELETE_SUCCESS))
            this.refreshRecords(this.state.communityId)
            this.setState({deleting: false})
          })
          .catch((error) => {
            this.context.store.dispatch(Flash.showFlashError(Constants.DOMAIN_DELETE_FAILURE))
            this.setState({deleting: false})
          })
        })
    })
  }

  private canAddDomains() {
    const maxDomainsAllowed = BillingPlan.getMaximumNumberOfDomainAuthentications(this.state.plan)
    return maxDomainsAllowed > 0 && maxDomainsAllowed > this.state.domains.length
  }

  private refreshRecords(communityId: string = null) {
    this.setState({
      domains: [],
    })
    new Dataset().loadCommunityDataset('emailDomains', communityId || this.state.communityId)
      .then((response) => {
        if (response[0].length > 0) {
          this.getDomainsWithNewsLetter(response[0])
        } else {
          this.setState({
            loaded: true,
          })
        }
      })
  }

  private getDomainsWithNewsLetter(domains: Domain[]) {
    const ids = domains.map((d: Domain) => d.id).join(',')
    new Dataset().loadCommunityDataset('emailDomainsNewsLetter',this.state.communityId,[{param: 'domainIdsVal', value: ids}])
      .then((response) => {
        domains.map((d: Domain) => {
          d.dns_data = JSON.parse(d.dns_data)
          const findDomain = response[0].find((x: Domain) => x.id === d.id)
          if(findDomain){
            d.community_name = findDomain.community_name
          }
        })
        this.setState({
          domains,
          loaded: true,
        })
      })
  }
}


type CreateDomainProps = GenericRedux.AllComponentPropsWithModal<any> & CreateDomainAdditionalProps
interface CreateDomainAdditionalProps {
  create: any,
  isSaving: boolean,
}

interface DomainAuthenticationState {
  domain: string,
  isSaving: boolean,
  validDomain: boolean,
}

const DataCell = ({host}) => {
  return <div className="domain-cell">
    <div className="cell-text">{host}</div>
    <CopyButton text={host}/>
  </div>
}

const HostCell = ({authHost, host}) => {
  return <DataCell host={host.replace(`.${authHost}`, '')}/>
}

export const DomainsTable = (props: any) =>
  <div className="domain-table">
    <div className="header-flex">
      <div>
        <p className="outer-text">
          Records for DNS host: &nbsp;
        </p>
      {props.d.domain}
      </div>
      <div className="newsletter ml-5">
        <p className="outer-text">
          Assigned to: &nbsp;
        </p>
        {props.d.community_name}
      </div>
      <div className="created">
        <p className="outer-text">
          Status: &nbsp;
        </p>
        <span style={{display: 'inline'}}>
          {statusBanner(props.d.status)}
        </span>
      </div>
    </div>
    <table className="actual-table">
      <thead>
            <tr>
              <td></td>
              <td>Type</td>
              <td>Name</td>
              <td>Value</td>
            </tr>
      </thead>
        <tbody>
            <tr>
              <td></td>
              <td>CNAME</td>
              <td><HostCell authHost={props.d.domain} host={props.d.dns_data.mail_cname.host}/></td>
              <td><DataCell host={props.d.dns_data.mail_cname.data}/></td>
            </tr>
            <tr>
              <td></td>
              <td>CNAME</td>
              <td><HostCell authHost={props.d.domain} host={props.d.dns_data.dkim1.host}/></td>
              <td><DataCell host={props.d.dns_data.dkim1.data}/></td>
            </tr>
            <tr>
              <td></td>
              <td>CNAME</td>
              <td><HostCell authHost={props.d.domain} host={props.d.dns_data.dkim2.host}/></td>
              <td><DataCell host={props.d.dns_data.dkim2.data}/></td>
            </tr>
            {renderDmarcRow(props)}
        </tbody>
    </table>
    <div className="validation-status">
      <div className="footer-text">
        <span>
          <p className="outer-text">
            Created:
          </p>
        &nbsp;
          {parseDate(props.d.created)}
        </span>
        <div>
          <p className="outer-text">
            Last Updated:
          </p>
          &nbsp;
          {parseDate(props.d.updated)}
        </div>
      </div>
      <div className="footer-button">
        {
        props.isAccountOwner ?
        <div>
        {props.isDeleting && props.currentId === props.d.id ?
        <Loading size="64" />
        :
        <Button className="delete-domain-btn" onClick={() =>  props.deleteDomain(props.d.id)}>
          Delete
        </Button>}
        </div>
        : null}
        {props.isValidating && props.currentId === props.d.id ?
        <Loading size="64" />
        :
        <Button onClick={() =>  props.validateDomain(props.d)}>
          Validate
        </Button>}
      </div>
    </div>
    <hr/>
  </div>



const renderDmarcRow = (props) => {
  const hasValidDmarc = !!props.d.dmarc && !!props.d.dmarc.valid

  return <tr className={!hasValidDmarc ? 'dangerRow' : ''}>
      <td>DMARC</td>
      <td>TXT</td>
      <td>{hasValidDmarc ? props.d.dmarc.domain : 'Not Found'}</td>
      <td>{hasValidDmarc ? props.d.dmarc.dmarcRecord : <a className="text-white" href={GlobalConstants.RASA_HELP_DMARC} target="_blank">Click here for more information</a>}</td>
    </tr>
}

const createDomainInitialState = {
  domain: '',
  isSaving: false,
  validDomain: true,
}
class CreateDomainComponentClass extends React.Component<CreateDomainProps, DomainAuthenticationState> {

  constructor(props: CreateDomainProps) {
    super(props)
    this.state = {
      domain: '',
      isSaving: false,
      validDomain: true,
    }
  }

  public render() {
    return (
      <div className="domain-auth">
        <HeaderComponent
          title={'Settings'}
          subTitle={'Domain Email'}
        />
        <DomainEmailSettingsModal data={this.props.modals}
          closeModal={this.props.closeModal}
          closeButtonText="OK"
          title="" />
        <p className="intro-text">
          Verify a domain to use as a "from" address when you send emails.<br/>
          Read our <a href={GlobalConstants.RASA_HELP_OVERVIEW_OF_DOMAIN_AUTH} target="_blank" rel="noopener">
            send from email documentation</a>.
        </p>
        <p className="intro-text">
          You'll need access to the DNS of this domain to be able to verify it.
        </p>
        <div className="input-flex">
          <span className="pre-input">WWW.</span>
          <Input  type="text"
                  name="domain"
                  placeholder="example.com"
                  invalid={!this.state.validDomain}
                  value={this.state.domain}
                  className="input"
                  id="domain"
                  onChange={(e) => this.updateDomain(e.target.value)}>
          </Input>
          {this.props.isSaving ?
          <div className="spinny">
            <Loading  size="64" />
          </div>
          :
          <Button className="auth-button"
                  disabled={!this.validForm() || this.state.isSaving}
                  onClick={this.createDomain}>
            Generate Records
          </Button>}
        </div>
        <hr/>
      </div>
    )
  }

  private createDomain = () => {
    this.props.create(this.state.domain, this.openModal)
    this.setState({domain: ''})
  }

  private openModal = () => {
    this.props.openModal(DomainEmailSettingsModal.key, {})
  }

  private validForm = () => {
    return this.state.validDomain && !isEmpty(this.state.domain)
  }

  private updateDomain = (domain: string) => {
    return this.setState({
      domain,
      validDomain: !isEmpty(domain) && this.isDomainValid(domain),
    })
  }

  private isDomainValid = (domain: string): boolean => {
    return Utils.validateDomain(domain) &&
           unvalidatableDomains.filter((d: string) => domain.endsWith(d)).length === 0
  }
}
export const CreateDomainComponent: ConnectedComponentClass<ComponentType<CreateDomainComponentClass>, Fields> = GenericRedux.registerNewComponentWithModals<any>(
  CreateDomainComponentClass,
  'domain_email_settings_modal',
  [DomainEmailSettingsModal.key],
  createDomainInitialState,
)
