import { isNil } from 'lodash'
import * as React from 'react'

import * as Types from './types'

export interface TextBoxProps {
  dirty?: boolean,
  disabled?: boolean,
  field: string,
  name: string,
  onChange: Types.OnChange,
  placeholder?: string,
  type?: string,
  value: string,
}

export class TextBox extends React.Component<TextBoxProps> {
  constructor(props: TextBoxProps) {
    super(props)
  }

  public render() {
    return <div className="textbox">
              <div className="field-label">
                {this.props.dirty && '* '}{this.props.name}
              </div>
              <input className={`field-textbox field-textbox-${this.props.field} ${this.props.dirty ? 'dirty' : ''}`}
                     disabled={isNil(this.props.disabled) ? false : this.props.disabled}
                     placeholder={this.props.placeholder}
                     value={this.props.value}
                     type={this.props.type || 'text'}
                     onChange={(e) => this.onChange(this.props.field, e.target.value)}>
              </input>
            </div>
  }

  private onChange = (field: string, value: any) => {
    this.setState({
      dirty: true,
    })
    this.props.onChange(field, value)
  }
}
