import React from 'react'
import { Button } from 'reactstrap'

export interface PreferenceItemProps extends React.HTMLAttributes<HTMLElement> {
  heading: string;
  showButton?: boolean,
  buttonText?: string,
  buttonDisabled?: boolean,
  onButtonClick?: any,
  isComingSoon?: boolean,
  preferenceKey: string,
  selectedPreference: string,
}

export class PreferenceItem extends React.Component<PreferenceItemProps> {
  constructor(props: PreferenceItemProps) {
    super(props)
  }
  public render() {
      return (
        <div className={this.props.className ? 'preference-box ' + this.props.className : 'preference-box'}>
          <div className="box-body">
            <div className="left">
              {this.props.heading && <div className="box-header">{this.props.heading}</div>}
              <div className="box-content">{this.props.children}</div>
            </div>
            <div className="right">
              {this.props.showButton &&
              <Button className="reload-button"
                      title={this.props.isComingSoon ? 'Coming Soon...' : this.props.buttonText }
                      onClick={this.props.onButtonClick}
                      disabled={this.disableButton()}>
                {this.props.buttonText}
              </Button>}
            </div>
          </div>
          <div className="clearfix"></div>
        </div>
      );
  }
  private disableButton = (): boolean => {
    if (this.props.buttonDisabled || (this.props.selectedPreference &&
        this.props.selectedPreference !== this.props.preferenceKey)) {
      return true
    } else {
      return false
    }
  }
}
