import { RasaContext } from 'context'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import * as Utils from 'generic/utility'
import * as React from 'react'
import '../styles.css'
import * as Types from '../types'
import { EmailPreference } from './emailPreference'
import { PasswordPreference } from './passwordPreference'
import { PersonalPreference } from './personalPreference'
import { PreferenceItem } from './preferenceItem'
import { TwoFactorPreference } from './twoFactorPreference'

interface ProfilePreferenceState {
  selectedPreference: string
}
export class ProfilePreferences extends React.Component<Types.ProfilePreferencesProps, ProfilePreferenceState> {
  public static contextType = RasaContext
  constructor(props: Types.ProfilePreferencesProps) {
    super(props)
    this.state = {selectedPreference: ''}
  }

  public render() {
    return <div className="profile-preferences">
      <PersonalPreference selectedPreference={this.state.selectedPreference} {...this.props}
        updateStatus={this.props.updateStatus} updateFailedStatus={this.props.updateFailedStatus} />
      <div className="box-separator"></div>
      <EmailPreference selectedPreference={this.state.selectedPreference} {...this.props}
        updateStatus={this.props.updateStatus} updateFailedStatus={this.props.updateFailedStatus} />
      <div className="box-separator"></div>
      <PasswordPreference selectedPreference={this.state.selectedPreference} {...this.props}
        onSelectPreference={this.onSelectPreference} />
      <div className="box-separator"></div>
      <div>
        <TwoFactorPreference selectedPreference={this.state.selectedPreference} {...this.props}
          onSelectPreference={this.onSelectPreference}/>
      <div className="box-separator"></div></div>
      <PreferenceItem preferenceKey="log-out" selectedPreference={this.state.selectedPreference}
        heading="Log out of all sessions, including this one"
        showButton={true}
        onButtonClick={this.logoutAllSessions}
        buttonText="Log out of All Sessions">
      </PreferenceItem>
    </div>
  }
  public logoutAllSessions = () => {
    AjaxWrapper.ajax(AjaxWrapper.getServerUrl() + '/auth/logout-all-sessions', HttpMethod.POST, {})
    .then(() => {
      Utils.logout()
    })
  }
  public onSelectPreference = (preferenceKey: string) => {
    this.setState({selectedPreference: preferenceKey})
  }
}
